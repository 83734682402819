<template>
  <v-timeline v-if="hasItems" dense clipped>
    <v-timeline-item
      large
      v-for="item in items"
      :key="item.id"
      :color="currencyColorMap.get(item.requested.code)"
      :icon="$helpers.getCurrencyIcon(item.requested.code)"
    >
      <v-list two-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="body-2">
              <span v-html="getMessage(item)" />
            </v-list-item-title>
            <v-list-item-subtitle>
              <request-status-label class="mx-0 subtitle-2" :status="item.requestStatus" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-timeline-item>
  </v-timeline>
  <p v-else class="body-2">{{ $t("message.no_record_found") }}</p>
</template>

<script>
  export default {
    name: "TravelImprestsTimeline",
    components: {
      RequestStatusLabel: () => import("@/components/global/request-status-label")
    },
    props: {
      items: Array,
      employee: Object
    },
    data: vm => ({
      currencyColorMap: new Map([
        ["TRY", "pink"],
        ["USD", "green"],
        ["EUR", "orange"]
      ])
    }),
    computed: {
      hasItems() {
        return this.items.length > 0;
      }
    },
    methods: {
      getMessage(item) {
        return this.$t("hr.travel.imprest_message", [
          `${item.employee.firstName} ${item.employee.lastName}`,
          this.$helpers.formattedDateTime(item.timestamp),
          this.$helpers.getConvertCurrency(item.requested)
        ]);
      }
    }
  };
</script>
